import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Header from '../components/header'
import Wrapper from '../components/wrapper'
import { margin, footerDistance } from '../distances'
import { css } from '@emotion/core'
import Button from '../components/button'

export default ({ data }) => (
  <Layout>
    <Header
      background={data.hero.image.fixed}
      title="Pagina niet gevonden"
      seoTitle="Pagina niet gevonden"
      showButton={false}
    />
    <Wrapper
      styles={[
        margin('bottom', footerDistance),
        css`
          max-width: 740px;
          text-align: center;
        `,
      ]}
    >
      <h2>Niet bestaande pagina</h2>
      <p>
        Het lijkt erop dat de pagina waar je naar op zoek bent niet bestaat of
        verplaatst is. Je kunt de adresbalk van de pagina controleren op
        typfouten, of het menu gebruiken om te vinden wat je zoekt.
      </p>
      <Button color="green" to="/">
        Naar de homepagina
      </Button>
    </Wrapper>
  </Layout>
)

export const query = graphql`
  query PageNotFound {
    hero: file(relativePath: { eq: "hero.jpg" }) {
      image: childImageSharp {
        fixed(width: 1600, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
